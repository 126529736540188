import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs & Wall Walks`}</p>
    <p>{`then,`}</p>
    <p>{`800M Run`}</p>
    <p>{`5 Wall Walks`}</p>
    <p>{`3 Rope Climbs to 15′`}</p>
    <p>{`50 Squats`}</p>
    <p>{`30 Pushups`}</p>
    <p>{`400M Run`}</p>
    <p>{`4 Wall Walks`}</p>
    <p>{`2 Rope Climbs`}</p>
    <p>{`40 Squats`}</p>
    <p>{`20 Pushups`}</p>
    <p>{`200M Run`}</p>
    <p>{`3 Wall Walks`}</p>
    <p>{`1 Rope Climb`}</p>
    <p>{`30 Squats`}</p>
    <p>{`10 Pushups`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      